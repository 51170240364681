.ui-player-list-item {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.ui-player-list-item:first-child {
    margin-top: 0;
}

.ui-player-list-item:last-child {
    margin-bottom: 0;
}

.ui-player-list-item > .ui-player-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 0%
}

.ui.segment.poetry-line {
    margin-top: 0;
    margin-left: 2rem;
    text-align: right;
}

.poetry-line-block {
    margin-top: 1rem;
}

.poetry-line-block:first-child {
    margin-top: 0;
}

.ui.checkbox.settings-checkbox {
    vertical-align: middle;
}